<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="save">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Tambah Hasil Pengecekan Aset</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-asset-check"
                      >Pengecekan Aset</router-link
                    >
                  </li>
                  <li class="breadcrumb-item" aria-current="page">
                    <router-link
                      :to="{ name: 'EditAssetCheck', params: { kode: kode } }"
                      >Edit</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Hasil
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button
                class="btn btn-save"
                :disabled="isSubmit || formData.detail.length == 0"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="row mt-3 align-items-center">
              <div class="col-12">
                <div class="title">Hasil Pengecekan Aset</div>
                <div class="sub-title">
                  Rincian dari Hasil Pengecekan Aset yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label class="">Penanggung Jawab :</label>
                  <Select2
                    disabled
                    v-model="formData.karyawan_perintah"
                    :options="optionKaryawan"
                    placeholder="Pilih Penanggung Jawab"
                    @change="myChangeEvent($event)"
                    @select="mySelectEvent($event)"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label class="">Pelaksana :</label>
                  <Select2
                    disabled
                    v-model="formData.karyawan_pelaksana"
                    :options="optionKaryawan"
                    placeholder="Pilih Pelaksana"
                    @change="myChangeEvent($event)"
                    @select="mySelectEvent($event)"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label class="">Gudang :</label>
                  <Select2
                    disabled
                    v-model="formData.id_gudang"
                    :options="OptionWerhouse"
                    placeholder="Pilih Gudang"
                    @change="myChangeEvent($event)"
                    @select="mySelectEvent($event)"
                  />
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table
                class="table table-bordered table-striped display"
                id="tableResult"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th rowspan="2">Kode</th>
                    <th rowspan="2">Nama Barang</th>
                    <th colspan="3" class="text-center">Qty</th>
                    <th rowspan="2">Satuan</th>
                    <th rowspan="2" style="width: 15%">Kondisi</th>
                  </tr>
                  <tr>
                    <th style="width: 8%">Sistem</th>
                    <th style="width: 8%">Hasil</th>
                    <th style="width: 8%">Selisih</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, index) in formData.detail" :key="index">
                    <td>{{ value.kode_aset }}</td>
                    <td>{{ value.nama_aset }}</td>
                    <td>{{ value.qty_sistem ? value.qty_sistem : "0" }}</td>
                    <td class="px-2">
                      <input
                        type="text"
                        class="form-control"
                        style="border: 1px solid #b3a9a9; background: white"
                        placeholder="Masukkan Hasil"
                        v-maska="'#################'"
                        @keyup="setHasil(index)"
                        v-model="value.hasil"
                        required
                      />
                    </td>
                    <td>{{ value.selisih }}</td>
                    <td>{{ value.nama_satuan }}</td>
                    <td>
                      <Select2
                        style="
                          border: 1px solid #b3a9a9;
                          background: white;
                          border-radius: 5px;
                        "
                        v-model="value.kondisi"
                        :options="optionKondisi"
                        placeholder="Pilih Kondisi"
                        @change="myChangeEvent($event)"
                        @select="mySelectEvent($event)"
                        required
                      />
                    </td>
                  </tr>
                  <tr v-if="formData.detail.length == 0">
                    <td colspan="7" class="text-center">Tidak ada data</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
// import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

// import $ from "jquery";

import { maska } from "maska";
import { cksClient, showAlert } from "../../../../helper";
import {
  get_AsetPengecekanPraSave,
  post_AssetPengecekanSaveResult,
} from "../../../../actions/aset/pengecekan";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // Datepicker,
    // TableLite
  },

  directives: {
    maska,
  },

  data() {
    return {
      optionKaryawan: [],
      OptionWerhouse: [],
      optionKondisi: [
        {
          id: "baik",
          text: "Baik",
        },
        {
          id: "rusak",
          text: "Rusak",
        },
      ],
      kode: this.$route.params.kode ? this.$route.params.kode : "",
      formData: {
        kode: this.$route.params.kode ? this.$route.params.kode : "",
        karyawan_pelaksana: "",
        karyawan_perintah: "",
        id_gudang: "",
        id_company: cksClient().get("_account").id_company,
        detail: [],
      },
      isSubmit: false,
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    // $("#tableResult").DataTable({
    //   info: false,
    // });
  },

  created() {
    this.getData();
  },

  methods: {
    save() {
      showAlert(this.$swal, {
        title: "PERHATIAN",
        msg: "Apakah anda yakin akan menyimpan hasil, semua data akan tersimpan secara otomatis dan tidak bisa diubah?",
        showCancelButton: true,
        confirmButtonText: "Ya, Simpan hasil",
        cancelButtonText: "Batal",
        onSubmit: () => {
          this.isSubmit = true;
          post_AssetPengecekanSaveResult(
            this.formData,
            () => {
              this.isSubmit = false;
              showAlert(this.$swal, {
                title: "BERHASIL!",
                msg: "Hasil pengecekan aset berhasil dicatat",
                onSubmit: () => {
                  this.$router.push({
                    name: "DetailAssetCheck",
                    params: {
                      kode: this.kode,
                    },
                  });
                },
              });
            },
            () => {
              this.isSubmit = false;
              showAlert(this.$swal, {
                title: "GAGAL!",
                msg: "Terjadi kesalahan, silakan ulangi kembali",
                showCancelButton: true,
                showConfirmButton: false,
              });
            }
          );
        },
      });
    },
    setHasil(key) {
      var jumlah = parseInt(this.formData.detail[key].qty_sistem)
        ? parseInt(this.formData.detail[key].qty_sistem)
        : 0;
      var hasil = parseInt(this.formData.detail[key].hasil)
        ? parseInt(this.formData.detail[key].hasil)
        : 0;
      var selisih = Math.abs(jumlah - hasil);
      this.formData.detail[key].selisih = selisih;
    },
    getData() {
      get_AsetPengecekanPraSave(
        { kode: this.kode },
        (res) => {
          var { data, karyawan, detail, gudang } = res;
          this.optionKaryawan = karyawan;
          this.OptionWerhouse = gudang;
          this.formData.id_gudang = data.id_gudang;
          this.formData.karyawan_pelaksana = data.karyawan_pelaksana;
          this.formData.karyawan_perintah = data.karyawan_perintah;
          this.formData.detail = detail;
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "AssetCheck",
              });
            },
          });
        }
      );
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

table input {
  border: none;
  padding: 7px;
}

table input:focus {
  background-color: transparent !important;
}

table tr:nth-child(2) th {
  border-radius: 0px !important;
}

tr:first-child th {
  vertical-align: middle;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
