import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"


const get_AsetPengecekanList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.ASET.PENGECEKAN.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


const get_AsetPengecekanDetail = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.ASET.PENGECEKAN.GET, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_AssetPengecekanSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.ASET.PENGECEKAN.SAVE, params).then(res => {
    if (res.status === 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_AsetPengecekanPraSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.ASET.PENGECEKAN.PRASAVE, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_AssetPengecekanSaveResult = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.ASET.PENGECEKAN.SAVE_RESULT, params).then(res => {
    if (res.status === 200 || res.status === 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}



export {
  get_AsetPengecekanList,
  get_AsetPengecekanDetail,
  post_AssetPengecekanSave,
  get_AsetPengecekanPraSave,
  post_AssetPengecekanSaveResult
}